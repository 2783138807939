import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Table, Button, Input, message, Form } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { post } from '../../services/fetch'; // Ensure this is the correct path
import './style.css';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'text' ? <Input /> : <Input.TextArea />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
        name={dataIndex}
        style={{ margin: 0 }}
        rules={[
          {
            required: true,
            message: `Please Input ${title}!`,
          },
          {
            max: 250,
            message: `${title} cannot exceed 250 characters`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>      
      ) : (
        children
      )}
    </td>
  );
};

const Faq = ({ courseId, onSubmitFAQ }) => {
  const { register, handleSubmit, formState: { errors }, reset,unregister } = useForm();
  const [form] = Form.useForm();
  const [faqFields, setFaqFields] = useState([]); // Initialize as an empty array
  const [existingFaqs, setExistingFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [showForm, setShowForm] = useState(false); // New state to control form visibility

  const isEditing = (record) => record.id === editingId;

  const edit = (record) => {
    form.setFieldsValue({
      question: record.question,
      answer: record.answer,
      id: record.id,
    });
    setEditingId(record.id);
  };

  const cancel = () => {
    setEditingId('');
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...existingFaqs];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setExistingFaqs(newData);
        setEditingId('');

        const updatedFaq = {
          ...row,
          id: item.id,
          course_id: courseId,
        };

        await post('update-course-faqs', updatedFaq, true, true);
        message.success('FAQ updated successfully');
        fetchFAQs();
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      message.error('Failed to update FAQ');
    }
  };

  const fetchFAQs = async () => {
    if (!courseId) {
      message.error('Cannot retrieve FAQs without a course ID');
      return;
    }

    setLoading(true);
    try {
      const response = await post('get-course-faqs', { course_id: courseId }, true, true);

      if (response.code === 200 && response.data) {
        setExistingFaqs(response.data);
        console.log("FAQs loading message triggered");
        message.success('FAQs loaded successfully');
      } else {
        console.log("FAQs not loaded message triggered");
        message.error("It looks like you haven't added any FAQs yet");
      }
    } catch (error) {
      console.error('Error retrieving FAQs:', error);
      message.error('An error occurred while retrieving FAQs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchFAQs = debounce(() => {
      if (courseId) {
        fetchFAQs();
      }
    }, 300);  // Adjust delay as necessary
  
    debouncedFetchFAQs();
  
    return () => debouncedFetchFAQs.cancel();
  }, [courseId]);

  const addFaqField = () => {
    setShowForm(true);
    setFaqFields([...faqFields, { id: Date.now() }]);
  };

  const removeFaqField = (index) => {
    unregister(`faq[${index}].question`);
    unregister(`faq[${index}].answer`);
    setFaqFields(faqFields.filter((_, i) => i !== index));
  };

  const submitFAQ = async (data) => {
    if (!courseId) {
      console.error('Course ID is missing');
      return;
    }

    try {
      await onSubmitFAQ(data);
      fetchFAQs();
      setFaqFields([{ id: Date.now() }]);
      reset();
    } catch (error) {
      message.error('Failed to submit FAQs');
    }
  };

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      editable: true,
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div className="flex">
            {editable && (
              <Button
                icon={<SaveOutlined />}
                onClick={() => save(record.id)}
                type="link"
                style={{ color: '#52c41a', marginRight: 8 }}
              />
            )}
            {!editable && (
              <Button
                icon={<EditOutlined />}
                onClick={() => edit(record)}
                type="link"
                style={{ color: '#1890ff', marginRight: 8 }}
                disabled={editingId !== ''}
              />
            )}
            <Button
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteFaq(record.id)}
              type="link"
              style={{ color: '#ff4d4f' }}
              danger
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'answer' ? 'textArea' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleDeleteFaq = async (faqId) => {
    try {
      const response = await post('delete-content', { id: faqId, type: "faq" }, true, true);
      if (response.code === 200) {
        message.success('FAQ deleted successfully');
        fetchFAQs();
      } else {
        message.error('Failed to delete FAQ');
      }
    } catch (error) {
      console.error('Error deleting FAQ:', error);
      message.error('An error occurred while deleting the FAQ');
    }
  };

  return (
    <div>
      <h3 className="text-lg font-semibold">Frequently Asked Questions</h3>

      <div className="flex justify-end items-center mb-4">
        <Button type="primary" style={{ backgroundColor: '#247FD', borderColor: '#247FD' }} onClick={addFaqField}>
          Add FAQ
        </Button>
      </div>

      {showForm && (
        <form onSubmit={handleSubmit(submitFAQ)}>
          {faqFields.map((field, index) => (
            <div key={field.id} className="mb-6 border p-4 rounded-md shadow-md">
              <div className="mb-4">
                <label htmlFor={`faq[${index}].question`} className="block mb-2 font-medium">
                  Question {index + 1}
                </label>
                <input
  type="text"
  {...register(`faq[${index}].question`, {
    required: 'Question is required',
    maxLength: {
      value: 250,
      message: 'Question cannot exceed 250 characters',
    },
  })}
  placeholder="Enter the question"
  className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-[#247FD] focus:border-transparent"
/>
{errors.faq && errors.faq[index]?.question && (
  <p className="text-red-500 mt-1">{errors.faq[index].question.message}</p>
)}
</div>

              <div className="mb-4">
                <label htmlFor={`faq[${index}].answer`} className="block mb-2 font-medium">
                  Answer {index + 1}
                </label>
                <textarea
                  {...register(`faq[${index}].answer`, { required: 'Answer is required' })}
                  placeholder="Enter the answer"
                  className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-[#247FD] focus:border-transparent"
                  rows="4"
                />
                {errors.faq && errors.faq[index]?.answer && (
                  <p className="text-red-500 mt-1">{errors.faq[index].answer.message}</p>
                )}
              </div>

              <Button
                type="default"
                onClick={() => removeFaqField(index)}
                className="bg-gray-200 hover:bg-gray-300 text-red-600 font-medium mt-2"
                style={{ borderColor: 'transparent' }}
              >
                Remove FAQ
              </Button>
            </div>
          ))}

          <div className="flex justify-end my-4">
            {faqFields.length > 0 && (
              <Button type="primary" htmlType="submit" style={{ backgroundColor: '#247FD', borderColor: '#247FD' }}>
                Submit FAQs
              </Button>
            )}
          </div>
        </form>
      )}

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={existingFaqs}
          columns={mergedColumns}
          rowKey="id"
          pagination={false}
          loading={loading}
        />
      </Form>
    </div>
  );
};

export default Faq;
