import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { User } from 'lucide-react'; 
import { LogOut } from 'lucide-react';
import { Popconfirm } from 'antd'; // Import Ant Design Popconfirm

const Nav = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  useEffect(() => {
    // Check if the '_GACOOKIE' token exists
    const token = Cookies.get('_GACOOKIE');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    // Delete the '_GACOOKIE' token
    Cookies.remove('_GACOOKIE');
    setIsAuthenticated(false);
    // Redirect to the login page or homepage
    window.location.href = '/';
  };

  const toggleLogoutMenu = () => {
    setShowLogout((prev) => !prev);
  };

  return (
    <nav className="bg-white text-white p-2 border-b-2 border-[#247F4D]">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo aligned to the left */}
        <div className="flex sm:items-center ">
          <img src="/assets/logo.png" alt="Medtalks Logo" className="h-20" />
        </div>

        {/* Navigation items aligned to the right */}
        <div className="flex items-center space-x-8">
          <ul className="flex space-x-8">
            <li>
              <a href="#" className="text-black  text-lg">
                Dashboard
              </a>
            </li>
            <li>
              <a href="/courses" className="text-black text-lg">
                Courses
              </a>
            </li>
          </ul>
          <div className="relative">
            {isAuthenticated ? (
              <div>
                <User
                  className="h-8 w-8 text-gray-500 cursor-pointer"
                  onClick={toggleLogoutMenu}
                />
                {showLogout && (
                  <div className="absolute right-0 mt-1 w-32 bg-white border border-gray-300 rounded shadow-lg">
                  <Popconfirm
                    title="Are you sure you want to log out?"
                    onConfirm={handleLogout} // Handle logout on confirmation
                    onCancel={() => setShowLogout(false)} // Close Popconfirm on cancel
                    okText="Yes"
                    cancelText="No"
                    placement="leftTop" // Customize tooltip placement
                  >
                    <div className="flex items-center cursor-pointer hover:bg-gray-100">
                      <LogOut className="text-black m-2" />
                      <p className="p-2 text-black">Logout</p>
                    </div>
                  </Popconfirm>
                </div>
                )}
              </div>
            ) : (
              <a href="/login" className="bg-[#027253] text-white px-4 py-2 rounded hover:bg-[#027253]">
                Login
              </a>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
