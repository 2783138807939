import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MultiSelectDropdown = ({ options, control, name, rules, placeholder, className }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '40px',
      '@media (max-width: 640px)': {
        minHeight: '50px', // Increase touch target size on mobile
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 6px',
      '@media (max-width: 640px)': {
        flexWrap: 'nowrap',
        overflowX: 'auto',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      '@media (max-width: 640px)': {
        fontSize: '14px',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      '@media (max-width: 640px)': {
        fontSize: '14px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      '@media (max-width: 640px)': {
        width: 'calc(100% - 20px)', // Adjust menu width on mobile
        margin: '0 10px',
      },
    }),
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Select
          {...field}
          isMulti
          options={options}
          closeMenuOnSelect={false}
          components={animatedComponents}
          className={`basic-multi-select ${className}`}
          classNamePrefix="select"
          placeholder={placeholder}
          onChange={(selectedOptions) => field.onChange(selectedOptions)}
          styles={customStyles}
        />
      )}
    />
  );
};

export default MultiSelectDropdown;