import React, { createContext, useState, useContext } from 'react';

const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
  const [courseId, setCourseId] = useState(null);
  const [chapterId, setChapterId] = useState(null);
  const [lectureId, setLectureId] = useState(null); // Add lectureId state

  // Function to set courseId as a string
  const setCourseIdString = (id) => {
    setCourseId(id ? String(id) : ''); // Ensure id is a string or an empty string
  };

  // Function to set chapterId as a string
  const setChapterIdString = (id) => {
    setChapterId(id ? String(id) : ''); // Ensure chapterId is a string or an empty string
  };

  // Function to set lectureId as a string
  const setLectureIdString = (id) => {
    setLectureId(id ? String(id) : ''); // Ensure lectureId is a string or an empty string
  };

  return (
    <CourseContext.Provider value={{ courseId, setCourseId: setCourseIdString, chapterId, updateChapterId: setChapterIdString, lectureId, setLectureId: setLectureIdString }}>
      {children}
    </CourseContext.Provider>
  );
};

export const useCourse = () => useContext(CourseContext);
