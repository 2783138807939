import React from 'react';

const ToggleSwitch = ({ checked, onChange, label }) => {
  return (
    <div className="flex items-center">
      <label className="flex items-center cursor-pointer">
        <span className="mr-2 text-gray-600 text-sm">{label}</span>
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            checked={checked}
            onChange={onChange}
          />
          <div
            className={`block w-14 h-6 rounded-full transition-colors ${
              checked ? 'bg-[#027253]' : 'bg-gray-300'
            }`}
          ></div>
          <div
            className={`dot absolute left-2 top-1 bg-white w-4 h-4 rounded-full transition-transform ${
              checked ? 'translate-x-6' : ''
            }`}
          ></div>
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
