import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Input, Space, Popconfirm, message } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { post } from '../../services/fetch';
import { useCourse } from '../../context/coursecontext';
import Cookies from 'js-cookie';

const CourseListingPage = () => {
  const [courses, setCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
  });

  useEffect(() => {
    document.title = "Courses / Medtalks Academy Admin"; 
  }, []);
  const { courseId } = useCourse(); // Get courseId from context

  const navigate = useNavigate();
  useEffect(() => {
    // Check if the '_GACOOKIE' token exists
    const token = Cookies.get('_GACOOKIE');
    if (!token) {
      // If token does not exist, redirect to the homepage
      navigate('/');
    }
  }, [navigate]);

  const fetchCourses = async (page = 1, perPage = 15) => {
    try {
      setLoading(true);
      setError(null);
      const res = await post("get-courses", { page, per_page: perPage }, true, true);
      if (res.code === 200) {
        setCourses(res.data.data);
        setAllCourses(res.data.data);
        setPagination({
          ...pagination,
          current: res.data.current_page,
          pageSize: res.data.per_page,
          total: res.data.total,
        });
      } else {
        setCourses([]);
        setAllCourses([]);
        setError("No courses found.");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      setError("Failed to fetch courses. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses(pagination.current, pagination.pageSize);
  }, []);

  const handleEdit = (courseId) => {
    navigate(`/add-course?id=${courseId}`); // Directly navigate to the edit page
  };

  const handleDelete = async (courseId) => {
    try {
      const payload = {
        id: courseId, // Sending courseId as id
        type: 'course',
      };
  
      await post(`delete-content`, payload, true, true);
      setCourses(courses.filter((course) => course.id !== courseId));
      setAllCourses(allCourses.filter((course) => course.id !== courseId));
      message.success('Course deleted successfully');
    } catch (error) {
      console.error('Error deleting course:', error);
      message.error('Failed to delete course');
    }
  };
  

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    fetchCourses(pagination.current, pagination.pageSize);
  };

  const handleAddCourse = () => {
    navigate('/add-course'); // Navigate to the add-course page
  };

  const columns = [
    {
      title: 'Sr No',
      dataIndex: 'srNo',
      key: 'srNo',
      sorter: (a, b) => a.srNo - b.srNo,
      sortOrder: sortedInfo.columnKey === 'srNo' && sortedInfo.order,
    },
    {
      title: 'Course Title',
      dataIndex: 'course_name',
      key: 'course_name',
      sorter: (a, b) => a.course_name.localeCompare(b.course_name),
      sortOrder: sortedInfo.columnKey === 'course_name' && sortedInfo.order,
    },
    {
      title: 'Short Description',
      dataIndex: 'short_desc',
      key: 'short_desc',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { value: 'live', text: 'Live' },
        { value: 'schedule', text: 'Schedule' },
        { value: 'draft', text: 'Draft' },
        { value: 'archive', text: 'Archive' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        console.log(`Filtering: ${record.status.trim()} with ${value}`); // Debug: Check what's being filtered
        return record.status.trim().toLowerCase().includes(value.toLowerCase());
      },
    },    
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record.id)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this course?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">Courses</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddCourse}
        >
          Add Course
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={courses}
        onChange={handleChange}
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          showQuickJumper: true,
        }}
      />
    </div>
  );
};

export default CourseListingPage;
