import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const SelectDropdown = ({ options, control, name, rules, placeholder, className }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '40px',
      borderColor: state.isFocused ? '#2684FF' : '#cccccc',
      boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#2684FF' : '#999999',
      },
      '@media (max-width: 640px)': {
        minHeight: '50px', // Increase touch target size on mobile
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
      '@media (max-width: 640px)': {
        padding: '0 12px', // More padding on mobile for easier interaction
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      '@media (max-width: 640px)': {
        fontSize: '16px', // Ensure text is legible on mobile
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      '@media (max-width: 640px)': {
        fontSize: '16px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      '@media (max-width: 640px)': {
        width: 'calc(100% - 20px)', // Adjust menu width on mobile
        margin: '0 10px',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '12px 8px',
      '@media (max-width: 640px)': {
        padding: '16px 12px', // Larger touch targets for options on mobile
      },
    }),
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Select
          {...field}
          options={options}
          components={animatedComponents}
          className={`basic-select ${className}`}
          classNamePrefix="select"
          placeholder={placeholder}
          onChange={(selectedOption) => field.onChange(selectedOption)}
          styles={customStyles}
          isSearchable
          isClearable
        />
      )}
    />
  );
};

export default SelectDropdown;