import React from 'react';
import Select from 'react-select';

const SingleDropdown = ({ label, name, value, options, onChange }) => {
  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : ''); // Pass the selected value directly
  };

  return (
    <div className="relative inline-block w-full text-left">
      {label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}
      <Select
        value={options.find((option) => option.value === value) || null}
        onChange={handleChange}
        options={options}
        placeholder={`Select ${label}`}
        classNamePrefix="react-select"
        className="react-select-container"
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: '#e8f4f0', // Hover background color for options
            primary: '#027253',  // Main color for focus state
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            minHeight: '20px', // Increase control height
            padding: '2px', // Adjust padding for larger clickable area
            fontSize: '14px', // Increase font size for larger text
            borderColor: '#d1d5db', // Tailwind gray-300
            boxShadow: 'none',
            '&:hover': {
              borderColor: '#027253', // Focus border color
            },
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '4px', // Adjust padding inside the value container
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: '16px', // Increase font size for selected value text
          }),
          menu: (base) => ({
            ...base,
            fontSize: '16px', // Increase font size for the dropdown options
          }),
        }}
      />
    </div>
  );
};

export default SingleDropdown;
