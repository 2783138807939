import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const App = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    step1Data: {},
    step2Data: {},
    step3Data: {},
  });

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get('id');

  // Update current step from URL on component mount
  useEffect(() => {
    const step = searchParams.get('step');
    if (step) {
      setCurrentStep(parseInt(step));
    }
  }, [location.search]);

  // Handle step submission and move to the next step
  const handleStepSubmit = (stepData, step) => {
    setFormData((prevData) => ({
      ...prevData,
      [`step${step}Data`]: stepData,
    }));
    // Increment step and update URL
    const nextStep = step + 1;
    setCurrentStep(nextStep);
    navigate(`?id=${courseId}&step=${nextStep}`);
  };

  // Handle switching steps manually
  const handleStepChange = (step) => {
    setCurrentStep(step);
    navigate(`?id=${courseId}&step=${step}`);
  };

  // Manually add a lecture (skip to step 3)
  const handleAddLecture = () => {
    setCurrentStep(3);
    navigate(`?id=${courseId}&step=3`);
  };

  // Render the current step component based on state
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 onSubmit={(data) => handleStepSubmit(data, 1)} />;
      case 2:
        return <Step2 onSubmit={(data) => handleStepSubmit(data, 2)} />;
      case 3:
        return <Step3 onSubmit={(data) => handleStepSubmit(data, 3)} />;
      default:
        return null;
    }
  };

  const steps = [
    { label: 'Course Information' },
    { label: 'Chapter' },
  ];

  return (
    <div className="min-h-screen bg-blue-100">
      <div className="flex flex-col md:flex-row">
        {courseId && (
          <aside className="w-full md:w-1/5 bg-white text-[#027253] p-6">
            <ul className="space-y-6 font-medium">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className={`flex items-center space-x-3 cursor-pointer ${
                    currentStep === index + 1 ? 'text-[#027253]' : 'text-gray-500'
                  }`}
                  onClick={() => handleStepChange(index + 1)}
                >
                  <span
                    className={`w-8 h-8 flex items-center justify-center rounded-full ${
                      currentStep === index + 1
                        ? 'bg-[#027253] text-white'
                        : 'bg-gray-200 text-gray-500'
                    }`}
                  >
                    {index + 1}
                  </span>
                  <span>{step.label}</span>
                </li>
              ))}
            </ul>
          </aside>
        )}
        <main className="w-full p-6">{renderCurrentStep()}</main>
      </div>
    </div>
  );
};

export default App;
