"use server";
import { decryptToken } from "../components/action/Enc";
import cookies from 'js-cookie';

async function post(url, data, isToken = false, isCache = true) {
  const key = process.env.REACT_APP_SECRET_KEY;
  const BASE_URL = "https://api.medtalks.in/api/adminacademy/v3/";
  let header = {};
  
  if (isToken) {
    const token = await decryptToken(key, cookies.get("_GACOOKIE"));
    header = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
  } else {
    header = {
      'Content-Type': 'application/json',
    };
  }

  try {
    const response = await fetch(BASE_URL + url, {
      method: 'POST',
      headers: header,
      cache: isCache ? 'default' : 'no-store',
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
      const res = await response.json();
      // if (res.code === 401) {
      //   // Handle session expiration
      //   alert("Session expired. Please log in again.");
      //   // Redirect to login page or handle logout
      //   window.location.href = '/login'; // Example redirect
      // }
      return res; // Return the error response
    }
    
    return await response.json();
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

async function get(url, params) {
  const BASE_URL = "https://api.medtalks.in/api/adminacademy/v3/";
  const key = process.env.REACT_APP_SECRET_KEY;
  const token = await decryptToken(key, cookies.get("_GACOOKIE"));

  try {
    const response = await fetch(BASE_URL + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      const res = await response.json();
      // if (res.code === 401) {
      //   // Handle session expiration
      //   alert("Session expired. Please log in again.");
      //   // Redirect to login page or handle logout
      //   window.location.href = '/login'; // Example redirect
      // }
      return res; // Return the error response
    }

    return await response.json();
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

export { post, get };

