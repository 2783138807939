import React from 'react';
import { Form, Input, Button, Upload, DatePicker, Select } from 'antd';
import { UserOutlined, MailOutlined, LockOutlined, CalendarOutlined, IdcardOutlined, PhoneOutlined, HomeOutlined } from '@ant-design/icons';

const { Option } = Select;

const Profile = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Form values:', values);
    // Handle form submission here
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Profile Information</h1>
      <Form
        form={form}
        name="profile"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="avatar"
          label="Profile Picture"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload name="avatar" listType="picture-card" maxCount={1}>
            <div>
              <UserOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>

        <Form.Item
          name="name"
          label="Full Name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="John Doe" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email!' },
            { type: 'email', message: 'Please enter a valid email!' }
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder="john.doe@example.com" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Change Password"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="New password" />
        </Form.Item>

        <Form.Item
          name="dateOfBirth"
          label="Date of Birth"
          rules={[{ required: true, message: 'Please select your date of birth!' }]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: 'Please select your gender!' }]}
        >
          <Select placeholder="Select your gender">
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="medicalLicenseNumber"
          label="Medical License Number"
          rules={[{ required: true, message: 'Please input your medical license number!' }]}
        >
          <Input prefix={<IdcardOutlined />} placeholder="ML123456" />
        </Form.Item>

        <Form.Item
          name="specialization"
          label="Specialization"
          rules={[{ required: true, message: 'Please select your specialization!' }]}
        >
          <Select placeholder="Select your specialization">
            <Option value="generalPractitioner">General Practitioner</Option>
            <Option value="pediatrician">Pediatrician</Option>
            <Option value="cardiologist">Cardiologist</Option>
            <Option value="dermatologist">Dermatologist</Option>
            <Option value="neurologist">Neurologist</Option>
            {/* Add more specializations as needed */}
          </Select>
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[{ required: true, message: 'Please input your phone number!' }]}
        >
          <Input prefix={<PhoneOutlined />} placeholder="+1 (123) 456-7890" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Office Address"
          rules={[{ required: true, message: 'Please input your office address!' }]}
        >
          <Input.TextArea prefix={<HomeOutlined />} placeholder="123 Medical Center Dr, City, State, ZIP" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ backgroundColor: '#247F4D', borderColor: '#247F4D' }}>
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Profile;