import React from 'react';
import JoditEditor from 'jodit-react';
import { Controller } from 'react-hook-form';

const EditorComponent = ({ control, name, rules }) => {
  const config = {
    readonly: false,
    height: 300,
    toolbarSticky: false,
        uploader: {
      insertImageAsBase64URI: true,
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <JoditEditor
          value={value}
          config={config}
          onBlur={(newContent) => onChange(newContent)}
        />
      )}
    />
  );
};

export default EditorComponent;