import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AddCourse from './components/AddCourse/AddCourse';
import LoginForm from './components/Login/Login';
import CourseListingPage from './components/Listing/step0';
import EditCoursePage from './components/AddCourse/editcourse';
import Step2 from './components/AddCourse/Step2';
import Step3 from './components/AddCourse/Step3';
import MCQComponent from './components/AddCourse/Step4';
import Profile from './components/Profile/profile';
function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="/courses" element={<CourseListingPage />} />

      {/* Step 1: Add Course */}
      <Route path="/add-course" element={<AddCourse />} />

      {/* Step 2: Add Chapter */}
      <Route path="/add-chapter" element={<Step2 />} />

      {/* Step 3: Add Lecture */}
      <Route path="/add-lecture" element={<Step3 />} />

      {/* Step 4: Add MCQ */}
      <Route path="/add-mcq/mcq" element={<MCQComponent />} />
            
            
      {/* Step 5: Profile */}
      {/* <Route path="/profile" element={< Profile/>} /> */}

    </Routes>
  );
}

export default AppRoutes;
