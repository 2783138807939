import * as CryptoJS from "crypto-js";
export function encryptToken(key, data) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return encryptedData;
}

export function decryptToken(key, encryptedData) {
    if (!encryptedData || !key) {
        return "";
    }
    if (encryptedData !== "" && key !== ""){
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
        return decryptedData.substring(1, decryptedData.length-1);
        console.log(decryptedData)
    }else{
        return "";
    }
  
}