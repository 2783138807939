import React from 'react';

const MyComponent = () => {
    const secretKey = process.env.REACT_APP_SECRET_KEY;

  console.log('Secret Key:', secretKey);

  return (
    <div>
      {/* Use secretKey for some purpose */}
    </div>
  );
};

export default MyComponent;
