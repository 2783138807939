import Cookies from 'js-cookie';
import { encryptToken } from "../components/action/Enc";
import {post} from '../services/fetch'
export async function authenticateUser(requestJson, res) {
    console.log(requestJson, res);
    let response = await post('login', requestJson);
    console.log(response);

    if (response.code !== 200) {
        return 'Authentication failed';   
    }
    if (response.data.length === 0) {
        return "register";
    }

    
    const key = process.env.REACT_APP_SECRET_KEY || ""; 
    const token = encryptToken(key, response.data.token);

    // Set cookies in the server response
    const sevenDaysInMs = 7 * 24 * 60 * 60; // 7 days in seconds
    const expires = new Date(Date.now() + sevenDaysInMs * 1000); // Optional if you use maxAge
    
    // Set the cookie
   // Set the cookie
Cookies.set('_GACOOKIE', token, {
    expires: 7, // Expire in 7 days
    sameSite: 'Strict',
});

    console.log('Cookie set:', Cookies.get('_GACOOKIE'));

     
    return true;
}
