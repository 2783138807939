import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import AppRoutes from './Routes';
import Nav from './components/Nav';
import { CourseProvider } from './context/coursecontext';

const AppWithNav = () => {
  const location = useLocation();

  return (
    <div>
      {/* Render Nav only if the current path is not `/` */}
      {location.pathname !== '/' && <Nav />}
      <AppRoutes />
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CourseProvider>
      <Router>
        <AppWithNav />
      </Router>
    </CourseProvider>
  </React.StrictMode>
);

reportWebVitals();
