import React from 'react';
import Select from 'react-select';
const CustomDropdown = ({ label, name, value, options, onChange = () => {} }) => {
  const handleChange = (selectedOption) => {
    onChange({ target: { name, value: selectedOption ? selectedOption.value : '' } });
  };

  return (
    <div className="relative inline-block w-full text-left">
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <Select
        value={options.find((option) => option.value === value) || null}
        onChange={handleChange}
        options={options}
        placeholder={`Select ${label}`}
        classNamePrefix="react-select"
        className="react-select-container"
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: '#e8f4f0', // Hover background color for options
            primary: '#027253',  // Main color for focus state
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            borderColor: '#d1d5db', // Tailwind gray-300
            boxShadow: 'none' ,
            '&:hover': {
              borderColor: '#027253', // Focus border color
            },
          }),
        }}
      />
    </div>
  );
};

export default CustomDropdown;
